<template>
  <div class="script">

      <br />
      <br />
       <p class="title text-center">{{ $t('update.title') }}</p>
      <updateTable />
  </div>
</template>

<script>
import updateTable from '@/components/updateTable';

export default {
  name: 'UpdateView',
  components: {
      updateTable,
    },
}
</script>