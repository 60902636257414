<template>
  <div>
      <v-data-table
        :headers="updateTableHeaders"
        :items="updates"
        :sort-by="'version'"
        :sort-desc="true"
        class="elevation-1"
        :no-data-text="$t('table_empty')"
        :footer-props="{
          'items-per-page-options': [20],
          'disableItemsPerPage': false,
          pageText: $t('table_footer.page_text')
        }"
    >
        <template v-slot:top>
          <v-toolbar flat>
              <v-toolbar-title>{{ $t('update_table.table_title') }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary--text"
              :disabled="!isAdministrator && isLicenseValid"
              @click="uploadUpdateDialog = true"
            >{{ $t('update_table.upload_button') }}</v-btn>
            <v-dialog v-model="uploadUpdateDialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ $t('update_table.upload_update_card.title') }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form v-model="isValid">
                    <v-file-input
                      accept=".update"
                      small-chips
                      show-size
                      v-model="file"
                    ></v-file-input>
                    <v-progress-linear
                      v-show="loading"
                      v-model="uploadProgress"
                      :query="true"
                      :indeterminate="uploadProgress == 100">
                    </v-progress-linear>
                    <p v-if="errors.internal_error">
                      <b>{{ $t('update_table.errors.internal') }}</b>
                    </p>
                    <p v-else-if="errors.out_of_disc_space_error">
                      <b>{{ $t('update_table.errors.disk_space') }}</b>
                    </p>
                    <p v-else-if="errors.file_upload_errors.length">
                      <b>{{ $t('update_table.errors.following_files') }}:</b>
                      <ul>
                        <li v-for="(error, index) in errors.file_upload_errors" :key="index"><span v-text="error"></span></li>
                      </ul>
                    </p>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="primary--text" @click="close">
                    {{ $t('update_table.upload_update_card.cancel_button') }}
                    </v-btn>
                    <v-btn :disabled="!isValid" color="primary--text" @click="uploadUpdateFile">
                    {{ $t('update_table.upload_update_card.upload_button') }}
                    </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to delete this device?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mb-2 primary--text"
                    @click="closeDelete"
                  >Cancel</v-btn>
                  <v-btn
                    class="mb-2 primary--text"
                    @click="deleteItemConfirm"
                  >OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <div v-if="isLicenseValid && isAdministrator && !checkIngState">
            <v-btn
              small
              class="mb-2 primary--text"
              v-show="isActionButtonVisible(item, 'check_btn')"
              @click="checkUpdate(item)"
            >
              {{ $t('update_table.check_button') }}
            </v-btn>
            <v-btn
              small
              class="mb-2 primary--text"
              v-show="isActionButtonVisible(item, 'install_btn')"
              @click="installUpdate(item)"
            >
              {{ $t('update_table.install_button') }}
            </v-btn>
            <v-btn
              small
              class="mb-2 primary--text"
              v-show="isActionButtonVisible(item, 'rollback_btn')"
              @click="restoreUpdate(item)"
            >
              {{ $t('update_table.roll_back') }}
            </v-btn>
            <v-btn
              small
              class="mb-2 primary--text"
              v-show="isActionButtonVisible(item, 'delete_btn')"
              @click="deleteUpdate(item)"
            >
              {{ $t('update_table.delete_button') }}
            </v-btn>
          </div>
        </template>
      </v-data-table>
  </div>
</template>

<script>
import Api from "@/api/api"

export default {
  name: 'Updates',
  data: () => ({
    isValid: false,
    file: null,
    // Errors
    errors: {
      file_upload_errors: [],
      internal_error: false,
      out_of_disc_space_error: false,
    },
    errorsDefault: {
      file_upload_errors: [],
      internal_error: false,
      out_of_disc_space_error: false,
    },

    loading: false,
    uploadUpdateDialog: false,
    actionButtonsVisibleState: {
      install_btn: false,
      check_btn: false,
      delete_btn: false,
      rollback_btn: false,
    },
  }),
  computed: {
    updateTableHeaders() {
      return [
        { text: this.$t('update_table.headers.name'), value: 'filename' },
        { text: this.$t('update_table.headers.version'), value: 'version' },
        { text: this.$t('update_table.headers.status'), value: 'status' },
        { text: this.$t('update_table.headers.description'), value: 'description' },
        { text: this.$t('update_table.headers.actions'), value: 'actions', sortable: false }
      ]},
    isAdministrator() {
      return this.$store.getters.isAdministrator
    },
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    currentGeneralVersion() {
      return this.$store.getters['update/getCurrentGeneralVersion']
    },
    updates() {
      return this.$store.state.update.updates_list
    },
    checkIngState() {
      return this.$store.getters['update/checkIngState']
    },
    uploadProgress() {
      return this.$store.state.uploadProgress
    },
  },
  methods : {
    installUpdate(update) {
      this.$store.dispatch('update/installUpdate', update)
    },
    checkUpdate(update) {
      this.$store.dispatch('update/checkUpdate', update)
    },
    restoreUpdate(update) {
      this.$store.dispatch('update/restoreUpdate', update)
    },
    deleteUpdate(update) {
      this.$store.dispatch('update/deleteUpdate', update)
    },
    isActionButtonVisible(item, button_name) {
      // Check action button visible state
      //
      // Attrs:
      //    item - Update item
      //    button_name - name of a button
      // Returns:
      //    bool:
      //      true  - button should be visible
      //      false - button should be hidden
      let actionButtonsVisibleState = this.checkActionBottomsVisible(item)
      return actionButtonsVisibleState[button_name]
    },
    checkActionBottomsVisible(item) {
      // Check action buttons visible state
      //
      // Attrs:
      //    item - Update item
      //
      // Returns:
      //    object in format: {
      //        install_btn: false,
      //        check_btn: false,
      //        delete_btn: false,
      //        rollback_btn: false,
      //    }
      let actionButtonsVisibleState = Object.assign({}, this.actionButtonsVisibleState)

      if (item.status === 'downloaded') {
        actionButtonsVisibleState.check_btn = true
        actionButtonsVisibleState.delete_btn = true
      } else if (item.status === 'checked') {
        if (this.currentGeneralVersion < item.version) {
          actionButtonsVisibleState.install_btn = true
        }
        actionButtonsVisibleState.delete_btn = true
      } else if (item.status === 'installed') {
        if (this.currentGeneralVersion === item.version) {
          actionButtonsVisibleState.rollback_btn = true
        }
        actionButtonsVisibleState.delete_btn = true
      } else if (item.status === 'error') {
        actionButtonsVisibleState.check_btn = true
        actionButtonsVisibleState.delete_btn = true
      }

      return actionButtonsVisibleState
    },
    uploadUpdateFile () {
      // Upload update file to backend
      //
      this.loading = true
      let formData = new FormData()

      formData.append('file', this.file)

      Api.uploadUpdateFile(formData)
      .then(
        (resp) => {
          console.log(resp)
          this.close()
        }
      )
      .catch(error => {
        if (error.response.status == 500) {
          this.errors.internal_error = true
        } else if (error.response.status == 413) {
          this.errors.out_of_disc_space_error = true
        } else if (error.response.status == 400) {
          this.errors.file_upload_errors = error.response.data
        } else {
          console.log(error.response)
        }
      })
      .finally(() => {
        this.loading = false
      })
    },
    close () {
      // Close upload update dialog.
      //
      this.uploadUpdateDialog = false
      this.$nextTick(() => {
        this.file = null
        this.errors = Object.assign({}, this.errorsDefault)
      })
    },
    connectToUpdateListWebsocket() {
      // Connect to ws and update update_list to the store
      //
      this.$store.dispatch('connectToWebsocket', 'ws/update/update_list').then( (wsConn) => {
        this.upd_list_ws_connection = wsConn
        this.upd_list_ws_connection.onmessage = (event) => {
          event.data.text().then(text => {
            let serialized_msg = JSON.parse(text)
            this.$store.commit('update/setUpdateList', serialized_msg)
          })
        }
      })
    },
  },
  created() {
    // Add actions column to table header
    // if (this.$store.getters.isAdministrator && this.isLicenseValid) {
    //   this.updateTableHeaders.push(
    //     { text: 'Actions', value: 'actions', sortable: false }
    //   )
    // }
  },
  mounted() {
    this.connectToUpdateListWebsocket()
  },
  destroyed() {
    this.upd_list_ws_connection.close()
  },
}
</script>

<style>

</style>